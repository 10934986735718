import { Role } from './enums';
import { Locale } from './portal-user.state';

export class GetPortalUserInfo {
  static readonly type = '[PortalUser] Get Portal User Info';
  constructor(public readonly bypassCache = false) { }
}

export class SetLocale {
  static readonly type = '[PortalUser] Set locale';
  constructor(public readonly locale: Locale) { }
}

export class ClearState {
  static readonly type = '[PortalUser] Clear State';
}

export class SetRole {
  static readonly type = '[PortalUser] Set Role';
  constructor(public readonly role: Role) { }
}

export class SetAvatar {
  static readonly type = '[PortalUser] Set Avatar';
  constructor(public readonly avatar: string) { }
}
