import { Injectable, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  Action,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { produce } from 'immer';
import { tap } from 'rxjs';

import { PatientGender } from '@trtl/types';

import { Role } from './enums';
import { User } from './models';
import { PortalUserApiService } from './portal-user-api.service';
import {
  ClearState,
  GetPortalUserInfo,
  SetAvatar,
  SetLocale,
  SetRole,
} from './portal-user.actions';

export type Locale = 'en' | 'ar';
export interface PortalUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: PatientGender;
  groupId: string;
  phone: string;
  createdAt: Date;
  updatedAt: Date;
  civilId: string;
  country: string;
  dateOfBirth: Date;
  height: number;
  comment: string;
  role: Role;
  avatar?: string;
}

export interface PortalUserStateModel {
  user: PortalUser;
  locale: Locale;
}


@State<PortalUserStateModel | null>({
  name: 'portalUser',
  defaults: null,
})
@Injectable()
export class PortalUserState {

  private readonly api = inject(PortalUserApiService);
  private readonly translocoService = inject(TranslocoService);

  @Selector([PortalUserState])
  static locale(state: PortalUserStateModel) { return state?.locale }

  @Selector([PortalUserState])
  static portalUser(state: PortalUserStateModel) { return state?.user }

  @Selector([PortalUserState.portalUser])
  static role(user: User | null) { return user?.role }

  @Selector([PortalUserState.locale])
  static direction(locale: Locale) { return locale?.startsWith('ar') ? 'rtl' : 'ltr' }

  @Action(GetPortalUserInfo, { cancelUncompleted: true })
  getPortalUserInfo(
    { getState, patchState }: StateContext<PortalUserStateModel>,
    { bypassCache }: GetPortalUserInfo,
  ) {
    const { user: { role } } = getState();

    return (!bypassCache && getState().user.id) || this.api.getInfo(role).pipe(
      tap(user => patchState({ user: { ...user, role } })),
    );
  }

  @Action(SetRole)
  setRole(
    { getState, patchState }: StateContext<PortalUserStateModel>,
    { role }: SetRole,
  ) {
    const { user } = getState() ?? {};
    const next = produce(user ?? {}, (draft) => {
      draft.role = role;
    });

    patchState({ user: next });
  }

  @Action(SetLocale)
  setLocale(
    { patchState }: StateContext<PortalUserStateModel>,
    { locale }: SetLocale,
  ) {
    this.translocoService.setActiveLang(locale);
    localStorage.setItem('locale', locale);
    patchState({ locale });
  }

  @Action(ClearState)
  clearState({ setState }: StateContext<PortalUserStateModel | null>) {
    setState(null);
  }

  @Action(SetAvatar)
  setAvatar(
    { setState }: StateContext<PortalUserStateModel>,
    { avatar }: SetAvatar,
  ) {
    setState(
      patch({ user: patch({ avatar }) }),
    )
  }
}
