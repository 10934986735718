import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { ENV } from '@trtl/env';

import { Role } from './enums';
import { PortalUser } from './portal-user.state';

@Injectable({
  providedIn: 'root',
})
export class PortalUserApiService {

  private readonly http = inject(HttpClient);
  private readonly env = inject(ENV);

  public getInfo(role: Role) {

    const path = role === Role.Patient
      ? `${this.env.api}/api/v1/patient/patients/profile`
      : `${this.env.api}/api/v1/doctor/users/info`;

    return this.http.get<PortalUser>(path);
  }
}
